<template>
  <div class="home-page">
    <div class="video-bg">
      <video
        muted="muted"
        loop="loop"
        src="/bg-video.mp4"
        preload="auto"
        class="my-video"
        autoplay
      ></video>
    </div>
    <div class="phone-wrapper">
      <div class="phone">
        <div class="phone-video">
          <video
            muted="muted"
            loop="loop"
            src="/phone.mp4"
            preload="auto"
            class="my-video"
            autoplay
          ></video>
        </div>
        <div class="phone-case">
          <img :src="require('/src/assets/phone-case.png')" />
        </div>
      </div>
      <div class="description">
        <h1>世界这么大</h1>
        <h2>我想去<img class="art-font" :src="require('/src/assets/kankan.png')"></h2>

        <div class="qrcodes">
          <div class="qrcode-button">
            <span>ios版本下载</span>
            <img :src="require('/src/assets/download.png')">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less">
.home-page {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.phone-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-60%, -60%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  z-index: 1;

  .phone {
    width: 300px;
    height: 600px;
    position: relative;
    margin-right: 60px;

    .phone-video {
      position: absolute;
      width: 266px;
      height: 564px;
      margin: 27px 14px;

      .my-video {
        width: 266px;
        height: 564px;
      }
    }

    .phone-case {
      position: absolute;
      width: 300px;
      height: 600px;
      background-repeat: no-repeat;
      background-size: contain;

      img {
        width: 100%;
      }
    }
  }

  .description {
    text-align: left;
    color: white;
    min-width: 330px;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 60px;
      margin: 0;
    }

    h2 {
      font-size: 48px;
      margin-top: 14px;
      margin-bottom: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .art-font {
      margin-left: 1rem;
      width: 100px;
    }
  }

  .qrcodes {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 40px;
    width: 100%;
  }

  .qrcode-button {
    box-sizing: border-box;
    background-color: white;
    color: #333;
    border-radius: 20px;
    width: 130px;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 10px;
    position: absolute;

    &:hover {
      img {
        display: block;
      }
    }

    span {
      display: inline-flex;
    }

    img {
      margin-top: 8px;
      width: 110px;
      height: 110px;
      display: none;
    }
  }
}

.my-video {
  min-height: 100%;
  min-width: 100%;
}

.video-bg {
  position: absolute;
  right: -30px;
  bottom: -30px;
  left: -30px;
  top: -30px;
  z-index: -20px;
  filter: blur(15px);

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: hsla(0, 0%, 0%, .3);
    z-index: 1;
    filter: blur(15px);
  }
}
</style>
