<template>
  <!-- eslint-disable max-len -->
  <div id="app">
    <LoginComponent :sections="sections" v-model="activeUuid" />
    <HomePage v-if="activeUuid === 'home'" />
    <section-item v-else :section="activeSection" :home-path="homePath" />
    <div class="copyright" :class="{ 'absolute': activeUuid === 'home' }">
      <div class="contact">
        <a href="mailto:Cckankan2020@gmail.com" target="_blank">Cckankan2020@gmail.com</a>
        <address style="marginTop: 10px;">Unit 17,80 West Beaver Creek Richmond Hill ON Canada L4B 1H3</address>
      </div>
      <p>KANKAN Technology Ltd. All Rights Reserved 本站所有内容版权所有，侵权必究。</p>
    </div>
  </div>
</template>

<script>
import LoginComponent from './components/login.vue';
import SectionItem from './components/section.vue';
import HomePage from './components/home-page.vue';

import config from './config.json';

export default {
  name: 'App',
  components: {
    LoginComponent,
    SectionItem,
    HomePage,
  },

  data() {
    return {
      sections: config.sections,
      activeUuid: 'home',
    };
  },

  computed: {
    homePath() {
      return config.loginPath;
    },

    activeSection() {
      return this.sections.find((item) => item.uuid === this.activeUuid);
    },
  },
};
</script>

<style lang="less">
html, body {
  margin: 0;
  padding: 0;
  position: relative;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.copyright {
  width: 100%;
  flex-shrink: 0;
  padding-top: 12px;
  padding-bottom: 8px;
  text-align: center;
  box-sizing: border-box;
  margin: 0 auto;
  color: #333333;

  &.absolute {
    position: absolute;
    left: 0;
    bottom: 0;
    color: #eeeeee;

    .contact a {
      color: #eeeeee;
    }
  }

  p {
    font-size: 12px;
    transform: scale(0.9);
    transform-origin: center;
    margin-bottom: 2px;
  }

  .contact {
    font-size: 14px;

    a {
      color: #333333;
    }
  }
}
</style>
